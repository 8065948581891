import React from "react";
import Layout from "../../components/layout";
import OurTerms from "../../components/terms";
import { graphql } from "gatsby";
import "./style.scss";

export default function Terms(props) {
  if (!props.data) return null;
  const data = props.data.allPrismicTerms.edges[0].node.dataRaw;

  return (
    <Layout>
      <div className="container">
        <OurTerms data={data} />
      </div>
    </Layout>
  );
}

export const term = graphql`
  query term {
    allPrismicTerms {
      edges {
        node {
          dataRaw
        }
      }
    }
  }
`;
