import React from "react";
import { RichText } from "prismic-reactjs";
import "./style.scss";

export default function OurTerms({ data }) {
  return (
    <div className="terms">
      <RichText render={data.title} />
      <RichText render={data.content} />
    </div>
  );
}
